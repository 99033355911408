<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <QuestionForm
        :data="empty"
        @save="onSave"
        :save_text="$pgettext('form','Create')"
        :autocomplete_tag="(query) => autocompleteTags({query: query, category: TagCategory.FAQ})"
    />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SuccessNotification} from "@/domain/core/exception/exceptions";
import QuestionForm from "@/domain/faq/components/QuestionForm.vue";
import {TagCategory} from "@/domain/core/utils/constants";

export default {
  name: 'NewFaqQuestion',
  computed: {
    TagCategory() {
      return TagCategory
    }
  },
  components: {QuestionForm},
  data() {
    return {
      empty: {
        question: {},
        tags: {},
        answer: {},
        is_active: false,
        order: 0
      }
    }
  },
  methods: {
    ...mapActions('faq', [
      'createQuestion',
    ]),
    ...mapActions('core', [
      'autocompleteTags',
    ]),
    async onSave(data) {
      await this.createQuestion({
        ...data,
        category: this.$route.params.category
      })
      await this.$router.push({name: 'faqQuestion.list', params: {category: this.$route.params.category}})
      throw new SuccessNotification(this.$pgettext('faq_question', 'Faq Question created!'))
    }
  }
}
</script>

<style scoped>

</style>